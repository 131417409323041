export default {
    props: {
        visible: {
            type: Boolean,
            default: false,
            required: false
        },
        flat: {
            type: Boolean,
            default: false,
            required: false
        }
    },
    data() {
        return {
            isModalVisible: false,
            canBeClosed: false
        }
    },
    methods: {
        toggleModalVisibility() {
            this.isModalVisible = !this.isModalVisible
        },
        toggleBluredContent(val) {
            const blured = document.querySelectorAll(".blured-content")
            if (!blured.length) {
                return
            }
            blured.forEach(b => {
                if (val) {
                    b.classList.remove("blured")
                    if (!this.visible) {
                        b.removeEventListener("click", this.bluredHandler)
                    }
                } else {
                    b.classList.add("blured")
                    if (!this.visible) {
                        b.addEventListener("click", this.bluredHandler)
                    }
                }
            })
        },
        bluredHandler(event) {
            if (!event.target.hasAttribute("data-default-auth-handler")) {
                this.hideModal()
            }
        },
        isPassword() {
            return (
                this.fields.length &&
                this.fields[0].type === "password" &&
                this.fields[1] &&
                this.fields[1].type === "password_confirmation"
            )
        },
        hideModal() {
            if (this.canBeClosed) {
                this.isModalVisible = false
            }
        },
        openModal() {
            this.isModalVisible = true
            this.closeDropdown()
        },
        closeDropdown() {
            document.querySelectorAll(".header__menu_item_dropdown").forEach(item => {
                item.parentElement.classList.remove("active")
            })
            document.body.classList.remove("open-menu")

            if (document.querySelector(".header__menu")) {
                document.querySelector(".header__menu").classList.remove("open")
                document.querySelector(".header__menu").classList.remove("open-child")
            }
        }
    },
    watch: {
        isModalVisible(val) {
            if (!this.flat) {
                //this.toggleBluredContent(!val)
            }

            if (val) {
                setTimeout(() => {
                    this.canBeClosed = true
                }, 1000)
            } else {
                this.canBeClosed = false
            }

            this.$forceUpdate()
        }
    }
}

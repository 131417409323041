<template>
    <div @click="handleOverlayClick" :class="{ 'public-modal-overlay': !flat }">
        <div
            ref="view"
            :data-id="_uid"
            class="public-modal-view custom-scroll"
            :class="[{ 'public-modal-view_flat': flat, 'public-modal-view_large': isLarge() }, modalType]"
        >
            <div class="public-modal-view__loader" v-if="loading">
                <SpinLoader color="#15A06E" :width="40" :stroke-width="4" />
            </div>
            <div
                class="public-modal-view-content"
                :class="{ 'public-modal-view-content_disabled': loading, 'public-modal-view-content_flat': flat }"
            >
                <template v-if="!withoutClose">
                    <CloseButtonLg v-if="largeCloseButton" @close="$emit('close')" />
                    <CloseButtonSm v-else @close="$emit('close')" />
                </template>

                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script>
import CloseButtonSm from "@components/Buttons/CloseButtonSm"
import SpinLoader from "@components/Loaders/SpinLoader"
import CloseButtonLg from "@components/Buttons/CloseButtonLg"

export default {
    props: {
        loading: {
            type: Boolean,
            default: false
        },
        flat: {
            type: Boolean,
            default: false
        },
        withoutClose: {
            type: Boolean,
            default: false
        },
        largeCloseButton: {
            type: Boolean,
            default: false
        },
        modalType: {
            type: String,
            default: "modal-registration"
        },
        stepKey: {
            type: String,
            default: ""
        }
    },
    name: "PublicModalView",
    components: { CloseButtonLg, SpinLoader, CloseButtonSm },
    mounted() {
        const el = document.querySelector(`[data-id='${this._uid}']`)

        if (el) {
            const observer = new MutationObserver(() => {
                this.$forceUpdate()
            })

            observer.observe(el, { attributes: true, childList: true, subtree: true })
        }
    },
    methods: {
        isLarge() {
            const el = document.querySelector(`[data-id='${this._uid}']`)
            if (el) {
                return el.getBoundingClientRect().height > window.innerHeight - 120
            }

            return false
        },
        handleOverlayClick(event) {
            if (this.withoutClose) {
                return
            }
            if (event.target !== event.currentTarget) {
                return
            }
            const selection = window.getSelection()

            if (
                selection &&
                selection.type === "Range" &&
                selection.anchorNode &&
                selection.anchorNode.parentNode.closest(".public-modal-view-content")
            ) {
                return
            }
            this.$emit("close")
        }
    },
    watch: {
        stepKey() {
            setTimeout(() => {
                this.$forceUpdate()
            })
        }
    }
}
</script>

<style lang="sass">
.public-modal-view
    .error
        color: #DD4141
        margin-top: 5px
.hidden-field
    opacity: 0
    height: 0
    padding: 0
    margin: 0
    position: absolute
.public-modal-overlay
    position: fixed
    top: 0
    left: 0
    right: 0
    bottom: 0
    z-index: 10000000
    backdrop-filter: blur(6px)
    @supports not (backdrop-filter: blur(6px))
        background: rgba(40, 40, 40, 0.53)
    @supports (backdrop-filter: blur(6px))
        backdrop-filter: blur(6px)
.mb-8
    margin-bottom: 8px
.mt-16
    margin-top: 16px
.mb-30
    margin-bottom: 30px
.mt-30
    margin-top: 30px
.mb-6
    margin-bottom: 6px
.mt-6
    margin-top: 6px
.mb-20
    margin-bottom: 20px
.mt-10
    margin-top: 10px
.mt-2
    margin-top: 2px
.authorization-flow
    min-height: 224px
</style>

<style scoped lang="sass">
.public-modal-view
    max-width: 440px
    width: 100%
    position: absolute
    top: 120px
    left: 50%
    transform: translateX(-50%)
    background: #fff
    z-index: 5
    box-shadow: 0 4px 10px rgba(128, 158, 191, 0.15)
    border-radius: 10px
    &_large
        transform: translate(-50%, -50%)
        top: 50%
        max-height: calc(100vh - 3%)
        overflow-y: auto
    @media (max-height: 660px)
        transform: translate(-50%, -50%)
        top: 50%
        max-height: calc(100vh - 3%)
        overflow-y: auto
    @media (max-width: 480px)
        left: 5%
        width: 90%
        transform: translateY(-50%)
        top: 50%
        max-height: calc(100vh - 12%)
        overflow-y: auto
    &__loader
        position: absolute
        top: 50%
        left: 50%
        transform: translate(-50%, -50%)
        z-index: 5
    &_flat
        position: relative !important
        box-shadow: none !important
        transform: none !important
        top: 0 !important
        left: 0 !important
        max-width: none !important
        border-radius: 0 !important
        width: 100% !important
    &.modal-locale
        max-width: 580px
        @media (max-width: 620px)
            left: 3%
            width: 94%
            transform: translateY(-50%)
            top: 50%
            max-height: calc(100vh - 3%)
        @media (max-width: 480px)
            &::v-deep
                .tooltip-close
                    svg
                        width: 14px
                        height: 14px
        .public-modal-view-content
            padding: 40px 24px
            @media (max-width: 620px)
                padding: 40px 20px
            @media (max-width: 480px)
                padding: 20px 10px

.public-modal-view-content
    padding: 35px 24px 30px 24px
    position: relative
    @media (max-width: 480px)
        padding: 35px 14px 30px 14px
    &_disabled
        pointer-events: none
        opacity: .4
    .tooltip-close
        position: absolute
        top: 10px
        right: 10px
    &_flat
        padding: 0
</style>
